<template>
    <layout-full-width :title="$tc('rule', 2)">
        <component-actions :action-filters="[]"
                           :bulk="bulk"
                           :bulk-actions="bulkActions"
                           :extended-search="extendedSearch"
                           :general-search-fields="generalSearchFields"
                           :itemsPerPage="itemsPerPage"
                           :page="page"
                           :primary-actions="primaryActions"
                           :total="total"
                           @applyFilters="onApplyFilters"
                           @changeItemsPerPage="onChangeItemsPerPage"
                           @deleteRule="deleteRule"
                           @paginate="onChangePage"
                           @refresh="listRules"/>
        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table :class="{'table-hover': !loading.RULE_LIST}" class="table">
                        <thead>
                        <component-table-header :heading-columns="headingColumns"
                                                :select-all-status="getSelectAllStatus()"
                                                :show-checkbox="true"
                                                @sort="onSortRules"
                                                @toggleCheckbox="toggleCheckbox"/>
                        </thead>
                        <tbody>
                        <template v-cloak v-if="!loading.RULE_LIST && rules.list.length > 0">
                            <tr v-for="(rule, i) in rules.list"
                                :key="'rule-' + i"
                                class="pointer"
                                @click="onClickRule($event, rule)">
                                <td class="align-middle">
                                    <button class="btn btn-checkbox" @click="toggleCheckbox(i)">
                                        <font-awesome-icon
                                            :class="rule.selected ? 'text-primary' : ''"
                                            :icon="rule.selected ? ['fas', 'check-square'] : ['far', 'square']"
                                            class="pe-none"/>
                                    </button>
                                </td>
                                <td class="align-middle">{{ rule.ruleDescShort }}</td>
                                <td class="align-middle">
                                        <span v-for="(object, j) in getUniqueObjects(rule.ruleConfig)"
                                              :key="'object-' + j"
                                              :class="getObjectClass(object)"
                                              class="badge me-1">
                                            {{ $tc('object-enums.' + object) }}
                                        </span>
                                </td>
                                <td class="align-middle"></td>
                                <td class="align-middle">
                                    <div class="dropdown">
                                        <button :id="'dropdown-' + i"
                                                aria-expanded="false"
                                                aria-haspopup="true"
                                                class="btn p-0 bg-transparent"
                                                data-bs-toggle="dropdown"
                                                type="button">
                                            <font-awesome-icon class="pe-none" icon="ellipsis-h"/>
                                        </button>
                                        <div :aria-labelledby="'dropdown-' + i"
                                             class="dropdown-menu dropdown-menu-right">
                                            <button class="dropdown-item"
                                                    @click="deleteRule(rule.ruleUniqueId)">
                                                {{ $t('actions.delete') }}
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-cloak v-if="!loading.RULE_LIST && rules.list.length === 0">
                            <tr>
                                <td colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('pages.rules.no-rules-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="loading.RULE_LIST">
                            <tr v-for="n in itemsPerPage" :key="'row-shimmer-' + n">
                                <td class="align-middle">
                                    <component-shimmer-checkbox/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="150px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer class="d-inline-block me-1" width="50px"/>
                                    <component-shimmer class="d-inline-block me-1" width="50px"/>
                                    <component-shimmer class="d-inline-block" width="50px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="100px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="15px"/>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <component-create-rule @listRules="listRules"/>

        <component-update-rule :active="update"
                               @close="update = false"
                               @update="onUpdateRule"/>

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentActions from '@/components/ui/ComponentActions.vue';
import ComponentCreateRule from '@/components/ComponentCreateRule';
import ComponentShimmer from "@/components/ComponentShimmer";
import shouldOpenSlideIn from "@/lib/should-open-slide-in";
import ComponentUpdateRule from "@/components/ComponentUpdateRule";
import ComponentShimmerCheckbox from "../../components/ui/ComponentShimmerCheckbox";
import ComponentTableHeader from '@/components/ui/ComponentTableHeader.vue';

export default {
    name: "PageRules",
    components: {
        ComponentShimmerCheckbox,
        LayoutFullWidth,
        ComponentActions,
        ComponentCreateRule,
        ComponentShimmer,
        ComponentUpdateRule,
        ComponentTableHeader
    },
    data() {
        return {
            filter: {},
            order: {meta_insert_dt: "DESC"},
            page: 1,
            itemsPerPage: 10,
            total: 0,
            limit: 10,
            generalSearchFields: ['gen_rule_desc_short'],
            extendedSearch: [
                {
                    name: this.$tc('name', 1),
                    technicalName: 'gen_rule_desc_short'
                }
            ],
            primaryActions: [
                {
                    text: this.$t('actions.new'),
                    icon: 'plus',
                    type: 'modal',
                    target: '#rule-create-wizard'
                }
            ],
            bulkActions: [
                {
                    text: this.$t('actions.delete'),
                    type: 'function',
                    target: 'deleteRule',
                    disabled: false
                }
            ],
            headingColumns: [
                {
                    title: this.$tc('name', 1),
                    sortField: 'gen_rule_desc_short'
                },
                {
                    title: this.$tc('object', 2),
                },
                {
                    title: this.$t('modified')
                },

                {
                    title: ""
                }
            ],
            bulk: false,
            update: false
        }
    },
    computed: {
        loading() {
            return {
                RULE_LIST: this.$store.state.loading.RULE_LIST
            }
        },
        rules() {
            return this.$store.state.RULE.rules
        }
    },
    methods: {
        listRules() {
            this.$store.dispatch('RULE/LIST', {
                filter: this.filter,
                order: this.order,
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                limit: this.limit,
            })
                .then(rules => {
                    this.total = rules.total;
                });
        },
        onSortRules(order) {
            this.order = order;
            this.listRules();
        },
        deleteRule(ruleUniqueId) {
            if (ruleUniqueId) {
                /*       ruleUniqueId = [ruleUniqueId]; */
            } else {
                return
                /*        ruleUniqueId = this.getSelectedIds() */
            }
            this.$store.commit('loading/RULE_DELETE', true);
            this.$store.dispatch('RULE/DELETE', {ruleUniqueId})
                .then(() => {
                    this.$store.dispatch('toast/success', {
                        title: this.$tc('toast.success.success'),
                        message: this.$tc('toast.success.rule-deleted')
                    });
                    this.listRules();
                })
                .catch(error => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: error.response.data.message
                    });
                });
        },
        async toggleCheckbox(i) {
            if (i === 'ALL') {
                if (this.getSelectAllStatus()) {
                    this.rules.list.forEach(rule => rule.selected = false);
                } else {
                    this.rules.list.forEach(rule => rule.selected = true);
                }
            } else {
                this.rules.list[i].selected = !this.rules.list[i].selected;
            }

            this.$forceUpdate();
            this.getActionButton();
        },
        getSelectAllStatus() {
            const count = this.rules.list.length;
            let selected = 0;

            this.rules.list.forEach(rule => {
                if (rule.selected) {
                    selected += 1;
                }
            });

            return count === selected && count !== 0;
        },
        getActionButton() {
            let count = 0;
            this.rules.list.forEach(rule => {
                if (rule.selected) {
                    count += 1;
                }
            })
            this.bulk = count !== 0;
        },
        getSelectedIds() {
            let selectedIds = [];

            this.rules.list.forEach(rule => {
                if (rule.selected) {
                    selectedIds.push(rule.ruleUniqueId)
                }
            });

            return selectedIds
        },
        onClickRule(event, rule) {
            if (!shouldOpenSlideIn(event.target)) {
                return;
            }

            this.$store.commit('backdrop/show');
            this.update = true;
            this.$store.dispatch('RULE/GET', rule.ruleUniqueId);
        },
        onUpdateRule() {
            this.$store.dispatch('toast/success', {
                title: this.$tc('toast.success.success'),
                message: this.$tc('toast.success.rule-updated')
            });
            this.listRules();
        },
        getUniqueObjects(ruleConfig) {
            let uniqueObjects = []

            function searchTree(obj) {
                if (Object.keys(obj).includes('object')) {
                    if (!uniqueObjects.includes(obj.object)) {
                        uniqueObjects.push(obj.object);
                    }
                }
                if (Object.keys(obj).length > 0) {
                    const keys = Object.keys(obj);

                    let i;
                    for (i = 0; i < Object.keys(obj).length; i++) {
                        const key = keys[i];
                        let target = obj[key];
                        if (typeof target === 'object') {
                            searchTree(obj[key])
                        }
                    }
                }
            }

            searchTree(ruleConfig);

            return uniqueObjects;
        },
        getObjectClass(object) {
            return {
                'bg-primary': object === 'CONTACT',
                'bg-info': object === 'RECIPIENT',
                'bg-danger': object === 'CAMPAIGN',
                'bg-dark': object === 'SEGMENT'
            }
        },
        onChangePage(page) {
            if (page <= 0) {
                return;
            }

            this.page = parseInt(page, 10);
            this.listRules();
        },
        onChangeItemsPerPage(itemsPerPage) {
            if (this.itemsPerPage === itemsPerPage) {
                return;
            }

            this.page = 1;
            this.itemsPerPage = itemsPerPage;
            this.listRules();
        },
        onApplyFilters(filters) {
            this.filter = filters;
            this.listRules();
        },
    },
    beforeMount() {
        this.listRules();
    }
}
</script>

<style lang="scss" scoped>
</style>
