<template>
    <div id="rule-create-wizard"
         ref="createRuleWizard"
         aria-hidden="true"
         class="modal fade"
         role="dialog"
         tabindex="-1">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <form @submit.prevent="onSubmit">
                    <div class="modal-header justify-content-between">
                        <button v-if="steps.current > 1"
                                class="border-0 bg-transparent me-2"
                                @click="previousStep()">
                            <span class="sr-only">{{ $t('actions.back') }}</span>
                            <font-awesome-icon icon="arrow-left"/>
                        </button>
                        <h5 class="modal-title">{{ $t('modal.create-rule.title') }}</h5>
                        <button :aria-label="$t('actions.close')"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                type="button"></button>
                    </div>
                    <div class="modal-body position-relative">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <label class="form-label" for="rule-desc-short">
                                    <strong>{{ $t('short-name') }}</strong>
                                </label>
                                <input id="rule-desc-short"
                                       v-model="rule.ruleDescShort"
                                       class="form-control"
                                       required="required"
                                       type="text">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 mb-3">
                                <label class="form-label" for="rule-desc-long">
                                    <strong>{{ $tc('name', 1) }}</strong>
                                </label>
                                <input id="rule-desc-long"
                                       v-model="rule.ruleDescLong"
                                       class="form-control"
                                       type="text">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 mb-3">
                                <label class="form-label" for="rule-comment">
                                    <strong>{{ $tc('description', 1) }}</strong>
                                </label>
                                <input id="rule-comment"
                                       v-model="rule.ruleComment"
                                       class="form-control"
                                       type="text">
                            </div>
                        </div>

                        <component-rule :arrayDepthIndexes="[]"
                                        :coloredBackground="true"
                                        :rules="rule.ruleConfig"
                                        @addCondition="onAddCondition"
                                        @addSubcondition="onAddSubcondition"
                                        @removeCondition="onRemoveCondition"
                                        @setRuleValue="onSetRuleValue"/>

                        <component-loading v-if="loading.WORKFLOW_CREATE" background-color="rgba(255,255,255,.6)"/>

                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import closeModal from "@/lib/close-modal";
import ruleObjects from "@/lib/rule-enum.js"
import ComponentLoading from "@/components/ui/ComponentLoading";
import ComponentRule from "@/components/ComponentRule";

export default {
    name: "ComponentCreateRule",
    components: {ComponentLoading, ComponentRule},
    computed: {
        loading() {
            return {
                RULE_CREATE: this.$store.state.loading.RULE_CREATE
            }
        },
        campaigns() {
            return this.$store.state.CAMPAIGN.campaigns;
        }
    },
    data() {
        return {
            steps: {
                current: 1,
                max: 2
            },
            ruleObjects,
            emptyRule: {
                object: '',
                attribute: '',
                operator: '',
                value: ''
            },
            rule: {
                ruleDescShort: '',
                ruleDescLong: '',
                ruleComment: null,
                ruleConfig: {
                    OR: [
                        {
                            object: '',
                            attribute: '',
                            operator: '',
                            value: ''
                        },
                    ],
                }
            }
        }
    },
    methods: {
        nextStep() {
            ++this.steps.current
        },
        previousStep() {
            --this.steps.current;
        },
        getStepClass(step) {
            return {
                active: step === this.steps.current,
                pending: step > this.steps.current,
                complete: step < this.steps.current
            };
        },
        onSetRuleValue(depthIndex, elementIndex, input, value) {
            let target = this.rule.ruleConfig;
            depthIndex.forEach(index => {
                const key = Object.keys(target);
                target = target[key][index]
            })
            let key = Object.keys(target);
            target = target[key];
            target[elementIndex][input] = value;
        },
        onAddCondition(condition, depthIndex, elementIndex) {
            let target = this.rule.ruleConfig;
            depthIndex.forEach(index => {
                const key = Object.keys(target);
                target = target[key][index]
            })
            let key = Object.keys(target);
            target = target[key];

            const element = target[elementIndex];

            if (key.toString() !== condition) {
                let newRule = {[condition]: [element, {...this.emptyRule}]};
                target.splice(elementIndex + 1, 0, newRule);
                target.splice(elementIndex, 1);
            } else {
                target.splice(elementIndex + 1, 0, {...this.emptyRule});
            }

            this.$forceUpdate();
        },
        onRemoveCondition(depthIndex, elementIndex) {
            let target = this.rule.ruleConfig;
            let targetParent;

            depthIndex.forEach((index, i) => {
                if (depthIndex.length - 1 === i) {
                    targetParent = {...target};
                }
                const key = Object.keys(target);
                target = target[key][index]
            });

            let targetKey = Object.keys(target);
            target = target[targetKey];

            let targetParentKey;
            if (depthIndex.length > 0) {
                targetParentKey = Object.keys(targetParent);
                targetParent = targetParent[targetParentKey];
            }

            target.splice(elementIndex, 1);
            if (target.length === 0 && depthIndex.length > 0) {
                targetParent.splice([depthIndex.pop()], 1);
            }
        },
        onAddSubcondition(depthIndex, elementIndex) {
            let target = this.rule.ruleConfig;
            depthIndex.forEach(index => {
                const key = Object.keys(target);
                target = target[key][index]
            })
            let key = Object.keys(target).toString();
            target = target[key];
            let operator;
            if (key === 'AND') {
                operator = 'OR'
            }
            if (key === 'OR') {
                operator = 'AND'
            }
            target.splice(elementIndex + 1, 0, {[operator]: [{...this.emptyRule}]});
            this.$forceUpdate();
        },
        onSubmit() {
            this.$store.dispatch('RULE/CREATE', {
                ruleUniqueId: null,
                ruleDescShort: this.rule.ruleDescShort,
                ruleDescLong: this.rule.ruleDescLong ? this.rule.ruleDescLong : null,
                ruleComment: this.rule.ruleComment ? this.rule.ruleComment : null,
                ruleConfig: this.rule.ruleConfig,
            })
                .then(rule => {
                    closeModal(this.$refs.createRuleWizard);
                    this.$emit('create', rule);
                    this.$store.dispatch('toast/success', {
                        title: this.$t('toast.success.success'),
                        message: this.$tc('toast.success.rule-created')
                    })
                    this.$emit('listRules')
                });
        },
    },
    beforeMount() {
        this.$store.dispatch('CAMPAIGN/LIST', {
            page: 1,
            limit: null,
            itemsPerPage: 100,
            filter: {},
            order: {meta_insert_dt: 'DESC'}
        });
    }
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
    width: 100%;
    height: 70vh;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}
</style>