<template>
    <component-shimmer height="16px" style="margin: 1px" width="14px"/>
</template>

<script>
import ComponentShimmer from "../ComponentShimmer";

export default {
    name: "ComponentShimmerCheckbox",
    components: {ComponentShimmer},
}
</script>

<style lang="scss" scoped></style>