<template>
    <component-slide-in :class="{ active }" @close="close" :size="size">

        <template v-if="!loading.RULE_GET && !loading.RULE_UPDATE && Object.keys(rule).length > 0">
            <div class="d-flex align-items-center mb-3">
                <component-avatar>{{ rule.ruleDescShort.substr(0, 1).toUpperCase() }}</component-avatar>
                <component-slide-in-title :editable="false"
                                          :subtitle="rule.ruleDescLong"
                                          :title="rule.ruleDescShort"
                                          :uuid="rule.ruleUniqueId"
                                          class="ms-3 flex-grow-1"/>
            </div>

            <nav class="nav nav-tabs mb-3">
                <button id="nav-general-tab"
                        aria-controls="nav-general"
                        aria-selected="false"
                        class="nav-link active"
                        data-bs-target="#nav-general"
                        data-bs-toggle="tab"
                        @click="size = ''"
                        role="tab"
                        type="button">{{ $t('general') }}
                </button>
                <button id="nav-configuration-tab"
                        aria-controls="nav-configuration"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-configuration"
                        data-bs-toggle="tab"
                        @click="size = 'lg'"
                        role="tab"
                        type="button">{{ $tc('configuration', 1) }}
                </button>
                <button id="nav-references-tab"
                        aria-controls="nav-references"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-references"
                        data-bs-toggle="tab"
                        @click="size = ''"
                        role="tab"
                        type="button">{{ $tc('reference', 2) }}
                </button>
                <button id="nav-comments-tab"
                        aria-controls="nav-comments"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-comments"
                        data-bs-toggle="tab"
                        @click="size = ''"
                        role="tab"
                        type="button">{{ $tc('comment', 2) }}
                </button>
                <button id="nav-activity-tab"
                        aria-controls="nav-activity"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-activity"
                        data-bs-toggle="tab"
                        @click="size = ''"
                        role="tab"
                        type="button">{{ $tc('activity', 1) }}
                </button>
            </nav>

            <div id="nav-tabContent" class="tab-content">
                <div id="nav-general"
                     aria-labelledby="nav-general-tab"
                     class="tab-pane fade show active"
                     role="tabpanel">
                    <form @submit.prevent="onUpdateRule">
                        <div class="row">
                            <div class="col-6">
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="rule-desc-short">{{ $t('short-name') }}</label>
                                    <input id="rule-desc-short"
                                           v-model="descShort"
                                           :aria-label="$t('short-name')"
                                           :class="{'is-invalid': this.$v.descShort.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="rule-desc-short"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.descShort.required" class="invalid-feedback">
                                        {{ $t('validation.error.required.desc-short') }}
                                    </div>
                                    <div v-if="!this.$v.descShort.maxLength" class="invalid-feedback">
                                        {{ $t('validation.error.maxLength.desc-short', {count: $v.descShort.$params.maxLength.max}) }}
                                    </div>
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="rule-desc-long">{{ $tc('name', 1) }}</label>
                                    <input id="rule-desc-long"
                                           v-model="descLong"
                                           :aria-label="$tc('name', 1)"
                                           :class="{'is-invalid': this.$v.descLong.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="rule-desc-long"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.descLong.maxLength" class="invalid-feedback">
                                        {{ $t('validation.error.maxLength.desc-long', {count: $v.descLong.$params.maxLength.max}) }}
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label small"
                                           for="rule-comment">{{ $tc('description', 1) }}</label>
                                    <textarea id="rule-comment"
                                              v-model="comment"
                                              :aria-label="$tc('comment', 1)"
                                              :class="{'is-invalid': this.$v.comment.$invalid}"
                                              :disabled="!edit.flag"
                                              aria-describedby="rule-comment"
                                              class="form-control form-control-sm"
                                              rows="6"
                                              type="text"></textarea>
                                    <div v-if="!this.$v.comment.maxLength" class="invalid-feedback">
                                        {{ $t('validation.error.maxLength.comment', {count: $v.comment.$params.maxLength.max}) }}
                                    </div>
                                </div>

                                <p>
                                    <button v-if="!edit.flag"
                                            class="btn btn-sm btn-outline-primary me-2"
                                            type="button"
                                            @click="onClickEdit">
                                        {{ $t('actions.edit') }}
                                    </button>
                                    <button v-if="edit.flag"
                                            :disabled="this.$v.$invalid"
                                            class="btn btn-sm btn-outline-success me-2">
                                        {{ $t('actions.update') }}
                                    </button>
                                    <button v-if="edit.flag"
                                            class="btn btn-sm btn-outline-danger"
                                            type="button"
                                            @click="edit.flag = false">
                                        {{ $t('actions.cancel') }}
                                    </button>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
                <div id="nav-configuration"
                     aria-labelledby="nav-configuration-tab"
                     class="tab-pane fade"
                     role="tabpanel">
                    <form @submit.prevent="onUpdateRule">
                        <component-rule :arrayDepthIndexes="[]"
                                        :coloredBackground="true"
                                        :rules="rule.ruleConfig"
                                        @addCondition="onAddCondition"
                                        @addSubcondition="onAddSubcondition"
                                        @removeCondition="onRemoveCondition"
                                        @setRuleValue="onSetRuleValue"/>
                        <div class="row">
                            <div class="col-12">
                                <button class="btn btn-primary mt-2 float-end">
                                    {{ $tc('actions.save') }}
                                </button>
                                <button class="btn btn-light mt-2 float-end me-2" type="button" @click="close">
                                    {{ $t('actions.cancel') }}
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
                <div id="nav-references"
                     aria-labelledby="nav-references-tab"
                     class="tab-pane fade"
                     role="tabpanel">
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                            <tr>
                                <td class="border-bottom-0" colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('no-references-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="nav-comments"
                     aria-labelledby="nav-comments-tab"
                     class="tab-pane fade"
                     role="tabpanel">
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                            <tr>
                                <td class="border-bottom-0" colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('no-comments-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="nav-activity"
                     aria-labelledby="nav-activity-tab"
                     class="tab-pane fade"
                     role="tabpanel">
                    <div class="list-group">
                        <div v-if="rule.meta_update_dt" class="list-group-item">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <p class="mb-0">{{ $t('activity-logs.rule-updated') }}</p>
                                <small>{{ rule.meta_update_dt | timestamp }}</small>
                            </div>
                        </div>
                        <div class="list-group-item">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <p class="mb-0">{{ $t('activity-logs.rule-created') }}</p>
                                <small>{{ rule.meta_insert_dt | timestamp }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="d-flex align-items-center mb-3">
                <component-shimmer class="rounded-circle d-block me-3" height="64px" width="64px"/>
                <div>
                    <component-shimmer class="mb-1"/>
                    <component-shimmer width="241.8px"/>
                </div>
            </div>

            <div class="d-flex mb-3">
                <component-shimmer class="me-2" height="37.2px" width="100px"/>
                <component-shimmer class="me-2" height="37.2px" width="100px"/>
                <component-shimmer class="me-2" height="37.2px" width="100px"/>
            </div>

            <div class="mb-3">
                <component-shimmer height="33.2px" width="162px"/>
            </div>

            <table class="table">
                <thead>
                <tr>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="n in 10" :key="'row-shimmer-' + n">
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </template>

    </component-slide-in>
</template>

<script>
import ComponentSlideIn from "@/components/ui/ComponentSlideIn";
import ComponentShimmer from "@/components/ComponentShimmer";
import ComponentAvatar from "@/components/ui/ComponentAvatar";
import ComponentSlideInTitle from "@/components/ui/ComponentSlideInTitle";
import ComponentRule from "@/components/ComponentRule";
import {maxLength, required} from "vuelidate/lib/validators";

export default {
    name: "ComponentUpdateRule",
    components: {ComponentSlideInTitle, ComponentAvatar, ComponentShimmer, ComponentSlideIn, ComponentRule},
    computed: {
        v() {
            return this.$v;
        },
        loading() {
            return {
                RULE_GET: this.$store.state.loading.RULE_GET,
                RULE_UPDATE: this.$store.state.loading.RULE_UPDATE
            }
        },
        rule() {
            return this.$store.state.RULE.rule;
        },
        descShort: {
            get() {
                return this.edit.flag ? this.edit.descShort : this.rule.ruleDescShort;
            },
            set(ruleDescShort) {
                this.edit.descShort = ruleDescShort;
            }
        },
        descLong: {
            get() {
                return this.edit.flag ? this.edit.descLong : this.rule.ruleDescLong;
            },
            set(ruleDescLong) {
                this.edit.descLong = ruleDescLong;
            }
        },
        comment: {
            get() {
                return this.edit.flag ? this.edit.comment : this.rule.ruleComment;
            },
            set(ruleComment) {
                this.edit.comment = ruleComment;
            }
        }
    },
    props: {
        active: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            size: '',
            edit: {
                flag: false,
                descShort: '',
                descLong: '',
                comment: ''
            },
            emptyRule: {
                object: '',
                attribute: '',
                operator: '',
                value: ''
            }
        }
    },
    methods: {
        close() {
            this.$store.commit('backdrop/hide');
            this.$emit('close');
        },
        onSetRuleValue(depthIndex, elementIndex, input, value) {
            let target = this.rule.ruleConfig;
            depthIndex.forEach(index => {
                const key = Object.keys(target);
                target = target[key][index]
            })
            let key = Object.keys(target);
            target = target[key];
            target[elementIndex][input] = value;
        },
        onAddCondition(condition, depthIndex, elementIndex) {
            let target = this.rule.ruleConfig;
            depthIndex.forEach(index => {
                const key = Object.keys(target);
                target = target[key][index]
            })
            let key = Object.keys(target);
            target = target[key];

            const element = target[elementIndex];

            if (key.toString() !== condition) {
                let newRule = {[condition]: [element, {...this.emptyRule}]};
                target.splice(elementIndex + 1, 0, newRule);
                target.splice(elementIndex, 1);
            } else {
                target.splice(elementIndex + 1, 0, {...this.emptyRule});
            }

            this.$forceUpdate();
        },
        onRemoveCondition(depthIndex, elementIndex) {
            let target = this.rule.ruleConfig;
            let targetParent;

            depthIndex.forEach((index, i) => {
                if (depthIndex.length - 1 === i) {
                    targetParent = {...target};
                }
                const key = Object.keys(target);
                target = target[key][index]
            });

            let targetKey = Object.keys(target);
            target = target[targetKey];

            let targetParentKey;
            if (depthIndex.length > 0) {
                targetParentKey = Object.keys(targetParent);
                targetParent = targetParent[targetParentKey];
            }

            target.splice(elementIndex, 1);
            if (target.length === 0 && depthIndex.length > 0) {
                targetParent.splice([depthIndex.pop()], 1);
            }
        },
        onAddSubcondition(depthIndex, elementIndex) {
            let target = this.rule.ruleConfig;
            depthIndex.forEach(index => {
                const key = Object.keys(target);
                target = target[key][index]
            })
            let key = Object.keys(target).toString();
            target = target[key];
            let operator;
            if (key === 'AND') {
                operator = 'OR'
            }
            if (key === 'OR') {
                operator = 'AND'
            }
            target.splice(elementIndex + 1, 0, {[operator]: [{...this.emptyRule}]});
            this.$forceUpdate();
        },
        onUpdateRule() {
            this.$store.dispatch('RULE/UPDATE', {
                ruleUniqueId: this.rule.ruleUniqueId,
                ruleDescShort: this.descShort,
                ruleDescLong: this.descLong,
                ruleComment: this.comment,
                ruleConfig: this.rule.ruleConfig,
            })
                .then(rule => {
                    this.edit.flag = false;
                    this.$emit('update', rule);
                    this.$gtag.event('RULE_UPDATE');
                })
                .catch(error => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: error.response.data.message
                    });
                });
        },
        onClickEdit() {
            this.edit.descShort = this.rule.ruleDescShort;
            this.edit.descLong = this.rule.ruleDescLong;
            this.edit.comment = this.rule.ruleComment;
            this.edit.flag = true;
        },
        closeOnEscape(e) {
            if (e.key === 'Escape' && this.active === true) {
                this.close();
            }
        }
    },
    validations: {
        descShort: {
            maxLength: maxLength(50),
            required
        },
        descLong: {
            maxLength: maxLength(255)
        },
        comment: {
            maxLength: maxLength(4000)
        },
    },
    watch: {
        active(newValue) {
            if (newValue === true) {
                window.addEventListener('keydown', this.closeOnEscape);
            } else {
                window.removeEventListener('keydown', this.closeOnEscape);
            }
        }
    },
}
</script>

<style scoped>

</style>
