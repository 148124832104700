const ruleObjects = {
    CONTACT: {
        attributes: {
            salutation: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            firstName: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            lastName: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            company: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            vatId: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            postcode: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            city: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            state: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            country: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            birthday: {
                selectorTypes: ['gt', 'gte', 'eq', 'lt', 'lte', 'dynamic'],
                inputType: 'date'
            }
        }
    },
    RECIPIENT: {
        attributes: {
            salutation: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            firstName: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            lastName: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            company: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            vatId: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            postcode: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            city: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            state: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            country: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            birthday: {
                selectorTypes: ['gt', 'gte', 'eq', 'lt', 'lte', 'dynamic'],
                inputType: 'date'
            }
        }
    },
    SEGMENT: {
        attributes: {
            segmentDescShort: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            }
        }
    },
    CAMPAIGN: {
        attributes: {
            campaignDescShort: {
                selectorTypes: ['of', 'nof'],
                inputType: 'text'
            },
            campaignType: {
                selectorTypes: ['of', 'nof'],
                inputType: 'select',
                options: []
            },
            campaignStatus: {
                selectorTypes: ['of', 'nof'],
                inputType: 'select',
                options: []
            }
        }
    }
}

export default ruleObjects;