<template>
    <div>
        <div v-for="(ruleValue, ruleKey, i) in rules"
             :key="'rule-' + i"
             :class="coloredBackground ? 'rule-background' : 'bg-white'"
             class="row m-0 p-2 rounded rule-background"
             style="padding-top: 20px !important;">
            <template v-for="(rule, j) in rules[ruleKey]">
                <div v-if="i > 0 || j > 0" :key="'subrule1-' + j" class="col-12">
                    <div class="separator">
                        <strong>
                            {{ ruleKey }}
                        </strong>
                    </div>
                </div>
                <template v-if="operatorExists(rule)">
                    <component-rule :key="'subrule-' + j"
                                    :arrayDepthIndexes="arrayDepthIndexes.concat([j])"
                                    :coloredBackground="!coloredBackground"
                                    :rules="rule"
                                    @addCondition="addCondition"
                                    @addSubcondition="addSubcondition"
                                    @removeCondition="removeCondition"
                                    @setRuleValue="setRuleValue"/>
                </template>
                <template v-else>
                    <div :key="'subrule-' + j" class="row p-2 m-0">
                        <div class="col-2 pe-0">
                            <label class="form-label" for="object">
                                <strong>{{ $tc('object', 1) }}</strong>
                            </label>
                            <select id="object"
                                    class="form-select"
                                    required
                                    v-bind:value="getRuleValue(j, 'object')"
                                    @change="clearRuleValues(arrayDepthIndexes, j, ['attribute', 'operator', 'value'])"
                                    v-on:input="setRuleValue(arrayDepthIndexes, j, 'object', $event.target.value)">
                                <option disabled value="">{{ $tc('actions.please-select') }}</option>
                                <option v-for="(ruleObjectValue, ruleObjectKey, k) in ruleObjects"
                                        :key="'object-' + k"
                                        :value="ruleObjectKey">
                                    {{ $tc('object-enums.' + ruleObjectKey) }}
                                </option>
                            </select>
                        </div>
                        <div class="col-3 pe-0">
                            <label class="form-label" for="attribute">
                                <strong>{{ $tc('attribute', 1) }}</strong>
                            </label>
                            <select id="attribute"
                                    class="form-select"
                                    required
                                    v-bind:value="getRuleValue(j, 'attribute')"
                                    @change="clearRuleValues(arrayDepthIndexes, j, ['operator', 'value'])"
                                    v-on:input="setRuleValue(arrayDepthIndexes, j, 'attribute', $event.target.value)">
                                <option disabled selected value="">{{ $tc('actions.please-select') }}</option>
                                <template v-if="rule.object">
                                    <option
                                        v-for="(ruleObjectAttributeValue, ruleObjectAttributeKey, l) in ruleObjects[rule.object].attributes"
                                        :key="'attribute-' + l"
                                        :value="ruleObjectAttributeKey">
                                        {{ $tc('object-enums.attribute.' + ruleObjectAttributeKey) }}
                                    </option>
                                </template>

                            </select>
                        </div>
                        <div class="col-3 pe-0">
                            <label class="form-label" for="operator">
                                <strong>{{ $tc('operator', 1) }}</strong>
                            </label>
                            <select id="operator"
                                    class="form-select"
                                    required
                                    v-bind:value="getRuleValue(j, 'operator')"
                                    v-on:input="setRuleValue(arrayDepthIndexes, j, 'operator', $event.target.value)">
                                <option disabled selected value="">{{ $tc('actions.please-select') }}</option>
                                <template v-if="rule.attribute">
                                    <option
                                        v-for="(operator, m) in ruleObjects[rule.object]['attributes'][rule.attribute].selectorTypes"
                                        :key="'operator-' + m"
                                        :value="operator">
                                        {{ $tc('object-enums.operator.' + operator) }}
                                    </option>
                                </template>
                            </select>
                        </div>
                        <div class="col-3 pe-0">
                            <label class="form-label" for="value">
                                <strong>{{ $tc('value', 1) }}</strong>
                            </label>
                            <template v-if="rule.operator !== 'dynamic'">
                                <input id="value"
                                       :disabled="!rule.operator"
                                       :type="rule.operator ? ruleObjects[rule.object]['attributes'][rule.attribute].inputType: 'text'"
                                       class="form-control"
                                       required
                                       v-bind:value="getRuleValue(j, 'value')"
                                       v-on:input="setRuleValue(arrayDepthIndexes, j, 'value', $event.target.value)">
                            </template>
                            <template v-else>
                                <div class="input-group">
                                    <select id="value"
                                            class="form-select"
                                            required
                                            style="max-width: 60px"
                                            v-bind:value="getDynamicValue(getRuleValue(j, 'value'), 0, arrayDepthIndexes, j)"
                                            v-on:input="setDynamicValue(arrayDepthIndexes, j, getRuleValue(j, 'value'), $event.target.value, 0)">
                                        <option value="PLUS">+</option>
                                        <option value="MINUS">-</option>
                                    </select>
                                    <input id="value"
                                           class="form-control"
                                           min="1"
                                           required
                                           style="max-width: 60px;"
                                           type="number"
                                           v-bind:value="getDynamicValue(getRuleValue(j, 'value'), 1, 0, arrayDepthIndexes, j)"
                                           v-on:input="setDynamicValue(arrayDepthIndexes, j, getRuleValue(j, 'value'), $event.target.value, 1)">
                                    <select id="value"
                                            class="form-select"
                                            required
                                            v-bind:value="getDynamicValue(getRuleValue(j, 'value'), 2, 0, arrayDepthIndexes, j)"
                                            v-on:input="setDynamicValue(arrayDepthIndexes, j, getRuleValue(j, 'value'), $event.target.value, 2)">
                                        <option value="DAY">Days</option>
                                        <option value="MONTH">Months</option>
                                        <option value="WEEK">Weeks</option>
                                    </select>
                                </div>
                            </template>
                        </div>
                        <div class="col-1">
                            <button class="btn btn-danger" style="margin-top: 27px;" type="button"
                                    @click="removeCondition(arrayDepthIndexes, j)">
                                <font-awesome-icon :icon="['far', 'trash-alt']"/>
                            </button>
                        </div>
                        <div class="col-12 mt-2">
                            <button class="btn btn-primary me-1"
                                    type="button"
                                    @click="addCondition('AND', arrayDepthIndexes, j)">
                                And
                            </button>
                            <button class="btn btn-primary"
                                    type="button"
                                    @click="addSubcondition(arrayDepthIndexes, j)">
                                {{ $t('actions.add-subcondition') }}
                            </button>
                        </div>
                    </div>
                </template>
            </template>
            <div class="row mt-2">
                <div class="col-12">
                    <button v-if="ruleKey === 'OR'"
                            class="btn btn-primary ms-2 mb-2"
                            type="button"
                            @click="addCondition('OR', arrayDepthIndexes, rules[ruleKey].length)">
                        Or
                    </button>
                </div>
            </div>
        </div>
    </div>


</template>
<script>
import ruleObjects from "@/lib/rule-enum.js"

export default {
    name: "ComponentRule",
    data() {
        return {
            ruleObjects
        }
    },
    props: {
        rules: {
            Type: Object,
            required: true
        },
        coloredBackground: {
            Type: Boolean,
            required: false
        },
        arrayDepthIndexes: {
            Type: Array,
            required: true
        }
    },
    computed: {},
    methods: {
        addCondition(operator, depthIndex, elementIndex) {
            this.$emit('addCondition', operator, depthIndex, elementIndex);
        },
        removeCondition(depthIndex, elementIndex, parentIndex) {
            this.$emit('removeCondition', depthIndex, elementIndex, parentIndex);
        },
        addSubcondition(operator, depthIndex) {
            this.$emit('addSubcondition', operator, depthIndex);
        },
        operatorExists(rule) {
            if ('AND' in rule || 'OR' in rule) {
                return true;
            }
            return false
        },
        getRuleValue(elementIndex, input) {
            let target = this.rules;
            let key = Object.keys(target);
            target = target[key][elementIndex][input];
            return target;
        },
        setRuleValue(depthIndex, elementIndex, input, value) {
            this.$emit('setRuleValue', depthIndex, elementIndex, input, value);
        },
        clearRuleValues(depthIndex, elementIndex, inputs) {
            inputs.forEach(input => {
                this.$emit('setRuleValue', depthIndex, elementIndex, input, '');
            })
        },
        getDynamicValue(formattedValue, index, depthIndex, elementIndex) {
            if (!formattedValue) {
                this.setRuleValue(depthIndex, elementIndex, 'value', 'PLUS_1_WEEK')
            }
            return formattedValue.split('_')[index]
        },
        setDynamicValue(depthIndex, elementIndex, formattedValue, newValue, index) {
            let newFormattedValue = formattedValue.split('_');
            newFormattedValue[index] = newValue;
            newFormattedValue = newFormattedValue.join('_');
            this.setRuleValue(depthIndex, elementIndex, 'value', newFormattedValue);
        }
    },
    mounted() {

    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.rule-background {
    background-color: lighten($primary, 45%);
}

.separator {
    color: #717273;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #717273;
}

.separator:not(:empty)::before {
    margin-right: .25em;
}

.separator:not(:empty)::after {
    margin-left: .25em;
}
</style>